import React from "react";

function Switch({ id, onChange }) {
  return (
    <div
      className="form-check form-switch form-switch-lg"
      style={{ display: "flex", width: "100%", justifyContent: "center" }}
    >
      <input
        className="form-check-input custom-check"
        type="checkbox"
        role="switch"
        id={id}
        onChange={onChange}
      />
    </div>
  );
}

export default Switch;
