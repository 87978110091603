import React from "react";
import { Button } from "react-bootstrap";

function ButtonCustom({ title, onClick, className }) {
  return (
    <Button
      className={`custom-button ${className}`}
      variant="dark"
      onClick={onClick}
    >
      {title}
    </Button>
  );
}

export default ButtonCustom;
