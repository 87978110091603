import React from "react";

function Input({ type, placeholder, onChange, value }) {
  return (
    <input
      type={type ? type : "text"}
      className="form-control mt-3"
      placeholder={placeholder && placeholder}
      onChange={onChange}
      value={value}
      autoComplete="off"
    />
  );
}

export default Input;
