import React, { useState, useEffect } from "react";
import token from "./Token";
import Web3 from "web3";
import { useDispatch } from "react-redux";
import { connect } from "../store/actions/connected";
import { chain } from "../store/actions/chain";
import { wallet } from "../store/actions/wallet";
import { useSelector } from "react-redux";
import Alert from "./small/Alert";
import Loading from "./small/Loading";
import Txprocessing from "./small/Txprocessing";
import Txsuccess from "./small/Txsuccess";
import { metamask } from "../store/actions/metamask";
import {
  getTokenSupply,
  isAddressValid,
  changeBurnFee,
  changeTaxFee,
  getChain,
  changeFeeAddress,
  waitForReceipt,
  includeFee,
  excludeFee,
  mintToken,
  burnFromDifAddress,
  burnAddress,
  includeReward,
  excludeReward,
  changeReflectFee,
  transferOwner,
} from "./functions";
import ButtonCustom from "./small/ButtonCustom";

function TokenAdmin() {
  const dispatch = useDispatch();
  const connection = useSelector((state) => state.connect);
  const wallstate = useSelector((state) => state.wallet);
  const connectedAddress = useSelector((state) => state.wallet[0]);
  const chainex = useSelector((state) => state.chain);
  const isMetamask = useSelector((state) => state.metamask);
  const [tokenAddress, setTokenAddress] = useState("");
  const [screen, setScreen] = useState("screen1");

  const [isToken, setIsToken] = useState(false);

  //tx notification states
  const [txError, setTxError] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const [txHappening, setTxHappening] = useState(false);

  const [txMessage, setTxMessage] = useState("");

  const [txSuccess, setTxSuccess] = useState(false);
  const [isMintable, setIsMintable] = useState(false);
  const [isBurnable, setIsBurnable] = useState(false);
  const [isFee, setIsFee] = useState(false);
  const [isHolder, setIsHolder] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  //standart token states
  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenSupply, setTokenSupply] = useState("");
  const [tokenDecimal, setTokenDecimal] = useState("");

  //fee token readabla states
  const [burnFee, setBurnFee] = useState("");
  const [taxFee, setTaxFee] = useState("");
  const [getFeeAccount, setGetFeeAccount] = useState("");
  const [isExcludedAddress, setIsExcludedAddress] = useState("");
  const [isExcludedBoolean, setIsExcludedBoolean] = useState("");

  //fee & holder Token common write States
  const [excludeFromFeeAddress, setExcludeFromFeeAddress] = useState("");
  const [includeInFeeAddress, setIncludeInFeeAddress] = useState("");

  //fee  Token  write States
  const [changeBurnFeeI, setChangeBurnFeeI] = useState("");
  const [changeTaxFeeI, setChangeTaxFeeI] = useState("");
  const [changeFeeAccountI, setChangeFeeAccountI] = useState("");

  //holder token states
  const [isExcludedRewardAddress, setIsExcludedRewardAddress] = useState("");
  const [isExcludedRewardBoolean, setIsExcludedRewardBoolean] = useState("");
  const [reflectFee, setReflectFee] = useState("");
  const [totalFeeDistributed, setTotalFeeDistributed] = useState("");

  //holder  Token  write States
  const [includeAccountInRewardI, setIncludeAccountInRewardI] = useState("");
  const [excludeAccountFromRewardI, setExcludeAccountFromRewardI] =
    useState("");
  const [changeReflectionFeeI, setChangeReflectionFeeI] = useState("");

  //ownable token read states
  const [owner, setOwner] = useState("");

  //ownable token write states
  const [newOwner, setNewOwner] = useState("");

  //burnable  Token  write States
  const [burnAmount, setBurnAmount] = useState("");
  const [burnFromAmount, setBurnFromAmount] = useState("");
  const [burnFromAddress, setBurnFromAddress] = useState("");

  //mint  Token  write States
  const [mintToAddress, setMintToAddress] = useState("");
  const [mintAmount, setMintAmount] = useState("");

  //holder or fee
  const [isFeeorHolder, setIsFeeorHolder] = useState(false);

  async function connectWeb3() {
    try {
      if (window.ethereum) {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        const cha = web3.currentProvider.networkVersion;
        dispatch(chain(getChain(cha)));
        dispatch(wallet(account));
        dispatch(connect());
      } else {
        dispatch(metamask());
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getTokenInfo() {
    if (
      tokenAddress.length === 42 &&
      tokenAddress[0] === "0" &&
      tokenAddress[1] === "x"
    ) {
      checkTokenStats();
    } else {
      setIsToken(false);
      setErrorMsg("This isn't a token contract address");
      setTxError(true);
      setTimeout(() => {
        setTxError(false);
      }, 5000);
    }
  }
  async function isContract(address) {
    try {
      const provider = window.ethereum;
      const web3 = new Web3(provider);
      // Adresin kodunu alın
      const code = await web3.eth.getCode(address);
      // Kodun boş olup olmadığını kontrol edin
      return code !== "0x" && code !== "0x0";
    } catch (error) {
      console.log("Hata:", error);
      return false;
    }
  }
  async function checkTokenStats() {
    //token mı değil mi kontrol
    try {
      /*     const val = await tokenContract.methods
        .totalSupply()
        .call({ from: connectedAddress });*/
      const isCt = await isContract(tokenAddress);
      if (isCt) {
        check();
        setIsToken(true);
        setScreen("loading");
      } else {
        setIsToken(false);
        setErrorMsg("This isn't a token contract address");
        setTxError(true);
        setTimeout(() => {
          setTxError(false);
        }, 5000);
        return;
      }
    } catch (error) {
      console.log(error);
      setIsToken(false);
      setErrorMsg("This isn't a token contract address");
      setTxError(true);
      setTimeout(() => {
        setTxError(false);
      }, 5000);
    }
  }

  const check = async () => {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const accounts = await provider.request({
      method: "eth_requestAccounts",
    });
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    try {
      await tokenContract.methods
        .mint(connectedAddress, "1000")
        .estimateGas({ from: connectedAddress });
      setIsMintable(true);
    } catch (error) {
      console.log(error);
      setIsMintable(false);
    }

    try {
      await tokenContract.methods
        .burn("100000")
        .estimateGas({ from: connectedAddress });
      setIsBurnable(true);
    } catch (error) {
      console.log(error);
      setIsBurnable(false);
    }

    try {
      await tokenContract.methods.getBurnFee().call({ from: connectedAddress });
      setIsFee(true);
      feeTokenInfo();
      setIsFeeorHolder(true);
    } catch (error) {
      console.log(error);
      setIsFee(false);
    }

    try {
      await tokenContract.methods.decimals().call({ from: connectedAddress });
      await tokenContract.methods
        .reflectionFee()
        .call({ from: connectedAddress });
      setIsHolder(true);
      holderTokenInfo();
      setIsFeeorHolder(true);
    } catch (error) {
      console.log(error);
      setIsHolder(false);
    }

    try {
      await tokenContract.methods.owner().call({ from: connectedAddress });
      setIsOwner(true);
      ownableTokenInfo();
    } catch (error) {
      console.log(error);
      setIsOwner(false);
    }
    setScreen("");
    StandartTokenInfo();
  };

  const validate = (b, a) => {
    console.log(a, b);
    try {
      if (b === "address") {
        if (a.length !== 42 || a[0] !== "0" || a[1] !== "x") {
          setErrorMsg("Input must be an ethereum address");
          setTxError(true);
          setTimeout(() => {
            setTxError(false);
          }, 5000);
          return false;
        } else {
          return true;
        }
      } else if (b === "fee") {
        if (Number(a) >= 0 && Number(a) <= 10 && Number.isInteger(Number(a))) {
          return true;
        } else {
          setErrorMsg("Fees must be a number between 0-10");
          setTxError(true);
          setTimeout(() => {
            setTxError(false);
          }, 5000);
          return false;
        }
      } else if (b === "amount") {
        if (Number.isInteger(Number(a))) {
          return true;
        } else {
          setErrorMsg("Token amount must be an integer");
          setTxError(true);
          setTimeout(() => {
            setTxError(false);
          }, 5000);
          return false;
        }
      }
    } catch (error) {
      return false;
    }
  };
  async function StandartTokenInfo() {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const accounts = await provider.request({ method: "eth_requestAccounts" });
    const tokenAbi = token[15].tokenAbi;

    const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    const decimals = await tokenContract.methods
      .decimals()
      .call({ from: connectedAddress });
    setTokenDecimal(decimals);

    const tokensupply = await tokenContract.methods
      .totalSupply()
      .call({ from: connectedAddress });
    setTokenSupply(tokensupply);

    const withoutDecimal = getTokenSupply(tokensupply, decimals);
    setTokenSupply(withoutDecimal);

    const tokenname = await tokenContract.methods
      .name()
      .call({ from: connectedAddress });
    setTokenName(tokenname);

    const tokensymbol = await tokenContract.methods
      .symbol()
      .call({ from: connectedAddress });
    setTokenSymbol(tokensymbol);
  }

  async function feeTokenInfo() {
    console.log("fee token fired");
    const web3 = new Web3(window.ethereum);
    const tokenC = new web3.eth.Contract(token[15].tokenAbi, tokenAddress);
    const burnfee = await tokenC.methods
      .getBurnFee()
      .call({ from: connectedAddress });
    setBurnFee(burnfee);

    const taxfee = await tokenC.methods
      .getTaxFee()
      .call({ from: connectedAddress });
    setTaxFee(taxfee);
    console.log(taxfee);

    const feeaccount = await tokenC.methods
      .getFeeAccount()
      .call({ from: connectedAddress });
    setGetFeeAccount(feeaccount);
  }

  async function holderTokenInfo() {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const accounts = await provider.request({ method: "eth_requestAccounts" });
    const tokenAbi = token[15].tokenAbi;

    const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    const decimals = await tokenContract.methods
      .decimals()
      .call({ from: connectedAddress });
    const reflectfee = await tokenContract.methods
      .reflectionFee()
      .call({ from: connectedAddress });
    setReflectFee(reflectfee);
    tokenContract.methods
      .totalFeesRedistributed()
      .call({ from: connectedAddress })
      .then((e) => {
        const feewdecimal = getTokenSupply(e, decimals);
        setTotalFeeDistributed(feewdecimal);
      });
  }

  async function ownableTokenInfo() {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    const own = await tokenContract.methods
      .owner()
      .call({ from: connectedAddress });
    setOwner(own);
  }

  async function isExcludedFunc() {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    const answer = await tokenContract.methods
      .isExcludedFromFee(isExcludedAddress)
      .call({ from: connectedAddress });
    console.log(typeof answer);
    setIsExcludedBoolean(answer);
  }

  async function isExcludedRewardFunc() {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    const answer = await tokenContract.methods
      .isExcluded(isExcludedRewardAddress)
      .call({ from: connectedAddress });
    console.log(answer);
    setIsExcludedRewardBoolean(answer);
  }

  function tx(error, transactionHash) {
    if (error) {
      setTxError(true);
      setErrorMsg(error.message);
      setTimeout(() => {
        setTxError(false);
      }, 5000);
    } else if (transactionHash) {
      function txDone(receipt) {
        if (receipt.status) {
          setTxHappening(false);
          setTxSuccess(true);
          setTimeout(() => {
            setTxSuccess(false);
            getTokenInfo();
          }, 5000);
        } else {
          setTxHappening(false);
          setTxError(true);
          setErrorMsg("Error : Transaction reverted");
          setTimeout(() => {
            setTxError(false);
          }, 5000);
        }
      }
      const txLink = chainex + "tx/" + transactionHash;
      setTxHappening(true);
      setTxMessage(txLink);
      waitForReceipt(transactionHash, txDone);
    }
  }

  return (
    <div className="container">
      <div className="page_wrapper">
        {screen === "loading" ? <Loading /> : null}
        {txHappening && <Txprocessing alertContent={txMessage} />}
        {txSuccess && (
          <Alert
            alertType={"alert-success"}
            alertContent={"Your transaction confirmed."}
          />
        )}
        {txError === true ? (
          <Alert alertType={"alert-danger"} alertContent={errorMsg} />
        ) : null}
        {isMetamask === false ? (
          <Alert
            alertType={"alert-danger"}
            alertContent={`You need to install Metamask to use this app.`}
          />
        ) : null}
        <section className="mb-140">
          <div className="row">
            <div
              className="col-xl-12 col-md-12 col-sm-12 p-5"
              style={screen !== "screen1" ? { display: "none" } : null}
            >
              <div className="p-5 mt-2 custom-card">
                <form>
                  <div className="form_input">
                    <h3 className="mt-3 mb-3 text-center">
                      Token Contract Address{" "}
                    </h3>
                    <input
                      type="text"
                      onChange={(e) => setTokenAddress(e.target.value)}
                      disabled={!connection && "disabled"}
                      className="form-control"
                      autoComplete="off"
                      aria-describedby="Token Name"
                      placeholder={
                        !connection
                          ? "Please Connect First"
                          : "Token Contract Address"
                      }
                    />
                  </div>
                </form>
                <div className="text-center">
                  <ButtonCustom
                    onClick={!connection ? connectWeb3 : getTokenInfo}
                    title={connection ? "Start" : "Connect"}
                    className="mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={screen === "loading" ? { display: "none" } : null}>
            <div
              className="row"
              style={isToken === false ? { display: "none" } : null}
            >
              <div className="col-lg-6 text-center p-3">
                <div className="custom-card p-4">
                  <h3 className="text-white">Name : {tokenName}</h3>
                </div>
              </div>
              <div className="col-lg-6 text-center p-3">
                <div className="custom-card p-4">
                  <h3 className="text-white">Symbol : {tokenSymbol}</h3>
                </div>
              </div>
              <div className="col-lg-6 text-center p-3">
                <div className="custom-card p-4">
                  <h3 className="text-white">Decimals : {tokenDecimal}</h3>
                </div>
              </div>
              <div className="col-lg-6 text-center p-3">
                <div className="custom-card p-4">
                  <h3 className="text-white">Supply : {tokenSupply}</h3>
                </div>
              </div>
            </div>
            <div className="row" style={!isFee ? { display: "none" } : null}>
              <div className="col-lg-6 text-center p-3">
                <div className="custom-card p-4">
                  <h3 className="text-white">Tax Fee : {taxFee}</h3>
                </div>
              </div>
              <div className="col-lg-6 text-center p-3">
                <div className="custom-card p-4">
                  <h3 className="text-white">Burn Fee : {burnFee}</h3>
                </div>
              </div>
              <div className="col-lg-6 text-center p-3">
                <div className="custom-card p-4">
                  <h3>Fee Address</h3>
                  <p style={{ fontSize: "0.8rem" }}>{getFeeAccount}</p>
                </div>
              </div>
              <div className="col-lg-6 text-center p-3">
                <div className="custom-card p-4">
                  <p className="white-text"> is Address Excluded From Fee?</p>{" "}
                  <>
                    <div className="form_input">
                      <input
                        type="text"
                        onChange={(e) => setIsExcludedAddress(e.target.value)}
                        disabled={!connection && "disabled"}
                        className="form-control"
                        autoComplete="off"
                        aria-describedby="Token Name"
                        placeholder={
                          !connection ? "Please Connect First" : "Address"
                        }
                      />
                      <span
                        className="white-text"
                        style={
                          typeof isExcludedBoolean !== "boolean"
                            ? { display: "none" }
                            : null
                        }
                      >
                        {!isExcludedBoolean ? "false" : "true"}
                      </span>
                    </div>

                    <ButtonCustom
                      onClick={() => {
                        validate("address", isExcludedAddress) &&
                          isExcludedFunc();
                      }}
                      title={connection ? "Check" : "Connect"}
                      className="mt-2"
                    />
                  </>
                </div>
              </div>
            </div>
            <div className="row">
              {isHolder && (
                <>
                  <div className="col-lg-6 text-center p-3">
                    <div className="custom-card p-4">
                      <h3 className="text-white">
                        Reflection Fee : {reflectFee}
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6 text-center p-3">
                    <div className="custom-card p-4">
                      <h3 className="text-white">
                        Total Fee Redistributed : {totalFeeDistributed}
                      </h3>
                    </div>
                  </div>
                </>
              )}
              {isOwner && (
                <div className="col-lg-6 text-center p-3">
                  <div className="custom-card p-4">
                    <h3 className="text-white">Token Owner</h3>
                    <p className="text-white" style={{ fontSize: "0.8rem" }}>
                      {owner}
                    </p>
                  </div>
                </div>
              )}
              {isHolder && (
                <div className="col-lg-6 text-center mt-2 p-3">
                  <div className="custom-card p-4">
                    <p className="white-text">
                      is Address Excluded From Reward?
                    </p>{" "}
                    <>
                      <div className="form_input">
                        <input
                          type="text"
                          onChange={(e) =>
                            setIsExcludedRewardAddress(e.target.value)
                          }
                          disabled={!connection && "disabled"}
                          className="form-control"
                          autoComplete="off"
                          aria-describedby="Token Name"
                          placeholder={
                            !connection ? "Please Connect First" : "Address"
                          }
                        />
                        <span
                          className="white-text"
                          style={
                            typeof isExcludedRewardBoolean !== "boolean"
                              ? { display: "none" }
                              : null
                          }
                        >
                          {!isExcludedRewardBoolean ? "false" : "true"}
                        </span>
                      </div>
                      <ButtonCustom
                        onClick={() => {
                          validate("address", isExcludedRewardAddress) &&
                            isExcludedRewardFunc();
                        }}
                        title={connection ? "Check" : "Connect"}
                        className="mt-2"
                      />
                    </>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              {isOwner && (
                <div className="col-lg-6 text-center mt-2 p-3">
                  <div className="custom-card p-4">
                    <form>
                      <div className="form_input">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="token-info-text"
                        >
                          Transfer Ownership{" "}
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setNewOwner(e.target.value)}
                          disabled={!connection && "disabled"}
                          className="form-control"
                          autoComplete="off"
                          aria-describedby="Token Name"
                          placeholder={
                            !connection
                              ? "Please Connect First"
                              : "New Owner Address"
                          }
                        />
                      </div>
                    </form>
                    <ButtonCustom
                      onClick={() => {
                        validate("address", newOwner) &&
                          transferOwner(
                            tokenAddress,
                            newOwner,
                            connectedAddress,
                            tx
                          );
                      }}
                      className="mt-2"
                      title={connection ? "Execute" : "Connect"}
                    />
                  </div>
                </div>
              )}

              {isHolder && (
                <>
                  <div className="col-lg-6 text-center mt-2 p-3">
                    <div className="custom-card p-4">
                      <form>
                        <div className="form_input">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="token-info-text"
                          >
                            Change Reflection Fee{" "}
                          </label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setChangeReflectionFeeI(e.target.value)
                            }
                            disabled={!connection && "disabled"}
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token Name"
                            placeholder={
                              !connection
                                ? "Please Connect First"
                                : "New Reflection Fee Percentage"
                            }
                          />
                        </div>
                      </form>
                      <ButtonCustom
                        className="mt-2"
                        title={connection ? "Execute" : "Connect"}
                        onClick={() => {
                          if (validate("fee", changeReflectionFeeI)) {
                            changeReflectFee(
                              tokenAddress,
                              changeReflectionFeeI,
                              connectedAddress,
                              tx
                            );
                            feeTokenInfo();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 text-center mt-2 p-3">
                    <div className="custom-card p-4">
                      <form>
                        <div className="form_input">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="token-info-text"
                          >
                            Exclude Account From Reward{" "}
                          </label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setExcludeAccountFromRewardI(e.target.value)
                            }
                            disabled={!connection && "disabled"}
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token Name"
                            placeholder={
                              !connection
                                ? "Please Connect First"
                                : "Address to Exclude From Rewards"
                            }
                          />
                        </div>
                      </form>
                      <ButtonCustom
                        title={connection ? "Execute" : "Connect"}
                        onClick={() => {
                          if (validate("address", excludeAccountFromRewardI)) {
                            excludeReward(
                              tokenAddress,
                              excludeAccountFromRewardI,
                              connectedAddress,
                              tx
                            );
                            holderTokenInfo();
                          }
                        }}
                        className="mt-2"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 text-center mt-2 p-3">
                    <div className="custom-card p-4">
                      <form>
                        <div className="form_input">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="token-info-text"
                          >
                            Include Account In Reward{" "}
                          </label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setIncludeAccountInRewardI(e.target.value)
                            }
                            disabled={!connection && "disabled"}
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token Name"
                            placeholder={
                              !connection
                                ? "Please Connect First"
                                : "Address to include reward"
                            }
                          />
                        </div>
                      </form>
                      <ButtonCustom
                        title={connection ? "Execute" : "Connect"}
                        onClick={() => {
                          if (validate("address", includeAccountInRewardI)) {
                            includeReward(
                              tokenAddress,
                              includeAccountInRewardI,
                              connectedAddress,
                              tx
                            );
                          }
                        }}
                        className="mt-2"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row">
              {isFee && (
                <>
                  <div className="col-lg-6 text-center mt-2 p-3">
                    <div className="custom-card p-4">
                      <form>
                        <div className="form_input">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="token-info-text"
                          >
                            Change Tax Fee{" "}
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setChangeTaxFeeI(e.target.value)}
                            disabled={!connection && "disabled"}
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token Name"
                            placeholder={
                              !connection
                                ? "Please Connect First"
                                : "New Tax Fee Percentage"
                            }
                          />
                        </div>
                      </form>
                      <ButtonCustom
                        className="mt-2"
                        title={connection ? "Execute" : "Connect"}
                        onClick={() => {
                          if (validate("fee", changeTaxFeeI)) {
                            changeTaxFee(
                              tokenAddress,
                              changeTaxFeeI,
                              connectedAddress,
                              tx
                            );
                            feeTokenInfo();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 text-center mt-2 p-3">
                    <div className="custom-card p-4">
                      <form>
                        <div className="form_input">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="token-info-text"
                          >
                            Change Fee Account{" "}
                          </label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setChangeFeeAccountI(e.target.value)
                            }
                            disabled={!connection && "disabled"}
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token Name"
                            placeholder={
                              !connection
                                ? "Please Connect First"
                                : "New Address to recieve fees"
                            }
                          />
                        </div>
                      </form>
                      <ButtonCustom
                        title={connection ? "Execute" : "Connect"}
                        className="mt-2"
                        onClick={() => {
                          if (validate("address", changeFeeAccountI)) {
                            changeFeeAddress(
                              tokenAddress,
                              changeFeeAccountI,
                              connectedAddress,
                              tx
                            );
                            feeTokenInfo();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 text-center mt-2 p-3">
                    <div className="custom-card p-4">
                      <form>
                        <div className="form_input">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="token-info-text"
                          >
                            Change Burn Fee{" "}
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setChangeBurnFeeI(e.target.value)}
                            disabled={!connection && "disabled"}
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token Name"
                            placeholder={
                              !connection
                                ? "Please Connect First"
                                : "New Burn Fee Percentage"
                            }
                          />
                        </div>
                      </form>
                      <ButtonCustom
                        onClick={() => {
                          if (validate("fee", changeBurnFeeI)) {
                            changeBurnFee(
                              tokenAddress,
                              changeBurnFeeI,
                              connectedAddress,
                              tx
                            );
                            feeTokenInfo();
                          }
                        }}
                        className="mt-2"
                        title={connection ? "Execute" : "Connect"}
                      />
                    </div>
                  </div>
                </>
              )}
              {isFeeorHolder && (
                <div className="col-lg-6 text-center mt-2 p-3">
                  <div className="custom-card p-4">
                    <form>
                      <div className="form_input">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="token-info-text"
                        >
                          Exclude From Fee{" "}
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setExcludeFromFeeAddress(e.target.value)
                          }
                          disabled={!connection && "disabled"}
                          className="form-control"
                          autoComplete="off"
                          aria-describedby="Token Name"
                          placeholder={
                            !connection
                              ? "Please Connect First"
                              : "Address to Exclude From fee"
                          }
                        />
                      </div>
                    </form>
                    <ButtonCustom
                      onClick={() => {
                        if (validate("address", excludeFromFeeAddress)) {
                          excludeFee(
                            tokenAddress,
                            excludeFromFeeAddress,
                            connectedAddress,
                            tx
                          );
                        }
                      }}
                      title={connection ? "Execute" : "Connect"}
                      className="mt-2"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row" style={!isToken ? { display: "none" } : null}>
              {isFeeorHolder && (
                <div className="col-lg-6 text-center mt-2 p-3">
                  <div className="custom-card p-4">
                    <form>
                      <div className="form_input">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="token-info-text"
                        >
                          Include In Fee
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setIncludeInFeeAddress(e.target.value)
                          }
                          disabled={!connection && "disabled"}
                          className="form-control"
                          autoComplete="off"
                          aria-describedby="Token Name"
                          placeholder={
                            !connection
                              ? "Please Connect First"
                              : "Address to include in fee"
                          }
                        />
                      </div>
                    </form>
                    <ButtonCustom
                      title={connection ? "Execute" : "Connect"}
                      onClick={() => {
                        if (validate("address", includeInFeeAddress)) {
                          includeFee(
                            tokenAddress,
                            includeInFeeAddress,
                            connectedAddress,
                            tx
                          );
                        }
                      }}
                      className="mt-2"
                    />
                  </div>
                </div>
              )}
              {isBurnable && (
                <div className="col-lg-6 text-center mt-2 p-3">
                  <div className="custom-card p-4">
                    <form>
                      <div className="form_input">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="token-info-text"
                        >
                          Burn Amount
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBurnAmount(e.target.value)}
                          disabled={!connection && "disabled"}
                          className="form-control"
                          autoComplete="off"
                          aria-describedby="Token Name"
                          placeholder={
                            !connection
                              ? "Please Connect First"
                              : "Burn Amount Without Decimals"
                          }
                        />
                      </div>
                    </form>
                    <ButtonCustom
                      className="mt-2"
                      title={connection ? "Burn" : "Connect"}
                      onClick={() => {
                        if (validate("amount", burnAmount)) {
                          burnAddress(
                            tokenAddress,
                            burnAmount,
                            connectedAddress,
                            tokenDecimal,
                            tx
                          );
                          StandartTokenInfo();
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row" style={!isToken ? { display: "none" } : null}>
              {isBurnable && (
                <div className="col-lg-6 text-center mt-2 p-3">
                  <div className="custom-card p-4">
                    <form>
                      <div className="form_input">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="token-info-text"
                        >
                          Burn From Address{" "}
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBurnFromAddress(e.target.value)}
                          disabled={!connection && "disabled"}
                          className="form-control"
                          autoComplete="off"
                          aria-describedby="Token Name"
                          placeholder={
                            !connection ? "Please Connect First" : "Address"
                          }
                        />
                      </div>
                      <div className="form_input">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="token-info-text"
                        >
                          Burn Amount{" "}
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBurnFromAmount(e.target.value)}
                          disabled={!connection && "disabled"}
                          className="form-control"
                          autoComplete="off"
                          aria-describedby="Token Name"
                          placeholder={
                            !connection
                              ? "Please Connect First"
                              : "Token Amount Without Decimals"
                          }
                        />
                      </div>
                    </form>
                    <ButtonCustom
                      className="mt-2"
                      title={connection ? "Burn" : "Connect"}
                      onClick={() => {
                        if (
                          validate("address", burnFromAddress) &&
                          validate("amount", burnFromAmount)
                        ) {
                          burnFromDifAddress(
                            tokenAddress,
                            burnFromAddress,
                            burnFromAmount,
                            connectedAddress,
                            tokenDecimal,
                            tx
                          );
                          StandartTokenInfo();
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              {isMintable && (
                <div className="col-lg-12 text-center mt-2 p-3">
                  <div className="custom-card p-4">
                    <form>
                      <div className="form_input">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="token-info-text"
                        >
                          Mint Receiver Address{" "}
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setMintToAddress(e.target.value)}
                          disabled={!connection && "disabled"}
                          className="form-control"
                          autoComplete="off"
                          aria-describedby="Token Name"
                          placeholder={
                            !connection ? "Please Connect First" : "Address"
                          }
                        />
                      </div>
                      <div className="form_input">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="token-info-text"
                        >
                          Amount{" "}
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setMintAmount(e.target.value)}
                          disabled={!connection && "disabled"}
                          className="form-control"
                          autoComplete="off"
                          aria-describedby="Token Name"
                          placeholder={
                            !connection
                              ? "Please Connect First"
                              : "Token Amount Without Decimals"
                          }
                        />
                      </div>
                    </form>
                    <ButtonCustom
                      onClick={() => {
                        mintToken(
                          tokenAddress,
                          mintToAddress,
                          mintAmount,
                          connectedAddress,
                          tokenDecimal,
                          tx
                        );
                        StandartTokenInfo();
                      }}
                      title={connection ? "Mint" : "Connect"}
                      className="mt-2"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default TokenAdmin;
