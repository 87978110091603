import React from "react";
import { Spinner } from "react-bootstrap";

function LoadingPage() {
  return (
    <div className="text-center">
      <a>
        <Spinner size="xl" />
      </a>
    </div>
  );
}

export default LoadingPage;
