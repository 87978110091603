import Footer from "./components/Footer";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Home from "./components/Home";
import { useEffect, useState } from "react";
import axios from "axios";
import { info } from "./info";
import { Helmet } from "react-helmet-async";
import { HelmetProvider } from "react-helmet-async";
import TokenAdmin from "./components/TokenAdmin";
import LoadingPage from "./components/LoadingPage";

function App() {
  const helmetContext = {};

  const [siteContent, setSiteContent] = useState(null);
  useEffect(() => {
    const getSiteContent = async () => {
      try {
        const res = await axios.get(info.apiUrl + "items/site_settings");
        setSiteContent(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSiteContent();
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <HelmetProvider context={helmetContext}>
          <BrowserRouter>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "100vh",
              }}
            >
              {siteContent && (
                <Helmet>
                  {/* Standard metadata tags */}
                  <title>
                    {siteContent.site_title && siteContent.site_title}
                  </title>
                  <meta
                    name="description"
                    content={
                      siteContent.site_description &&
                      siteContent.site_description
                    }
                  />
                  {/* End standard metadata tags */}
                  {/* Facebook tags */}
                  <meta property="og:type" content={"website"} />
                  <meta
                    property="og:title"
                    content={siteContent.site_title && siteContent.site_title}
                  />
                  <meta
                    property="og:description"
                    content={
                      siteContent.site_description &&
                      siteContent.site_description
                    }
                  />
                  <link
                    rel="icon"
                    href={
                      siteContent.favicon &&
                      info.apiUrl + "assets/" + siteContent.favicon
                    }
                    type="image/webp"
                  />
                  {/* End Facebook tags */}
                  {/* Twitter tags */}
                  <meta name="twitter:creator" content={"name"} />
                  <meta
                    name="twitter:title"
                    content={siteContent.site_title && siteContent.site_title}
                  />
                  <meta
                    name="twitter:description"
                    content={
                      siteContent.site_description &&
                      siteContent.site_description
                    }
                  />
                  {/* End Twitter tags */}
                </Helmet>
              )}

              <Header
                logo={siteContent && siteContent.logo && siteContent.logo}
              />
              {siteContent ? (
                <div style={{ paddingBottom: "5rem" }}>
                  <Routes>
                    <Route
                      path="/"
                      element={<Home testMode={siteContent.test_mode_on} />}
                    />
                    <Route path="/token-admin" element={<TokenAdmin />} />
                  </Routes>
                </div>
              ) : (
                <LoadingPage />
              )}

              <Footer
                text={
                  siteContent &&
                  siteContent.footer_text &&
                  siteContent.footer_text
                }
              />
            </div>
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </div>
  );
}

export default App;
