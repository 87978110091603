import React from "react";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { connect } from "../store/actions/connected";
import { chain } from "../store/actions/chain";
import { wallet } from "../store/actions/wallet";
import { metamask } from "../store/actions/metamask";
import { useSelector } from "react-redux";
import { getChain } from "./functions";
import Web3 from "web3";
import ButtonCustom from "./small/ButtonCustom";
import { info } from "../info";
import axios from "axios";
import { useMediaQuery } from "@uidotdev/usehooks";

function Header({ logo }) {
  const dispatch = useDispatch();
  const connection = useSelector((state) => state.connect);
  const connectedAddress = useSelector((state) => state.wallet[0]);
  const chainex = useSelector((state) => state.chain);
  const [isHovered, setIsHovered] = useState(false);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const [social, setSocial] = useState("");

  const [siteContent, setSiteContent] = useState(null);
  useEffect(() => {
    const getSiteContent = async () => {
      try {
        const res = await axios.get(info.apiUrl + "items/header_menu");
        setSiteContent(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSiteContent();
  }, []);

  async function connectWeb3() {
    try {
      if (window.ethereum) {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        const cha = web3.currentProvider.networkVersion;
        const chainf = getChain(cha);
        dispatch(chain(chainf));
        dispatch(wallet(account));
        dispatch(connect());
        provider.on("chainChanged", (_chainId) => window.location.reload());
      } else {
        dispatch(metamask());
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      {isHovered && isSmallDevice && (
        <>
          <div
            style={{
              width: "20%",
              backgroundColor: "#272B3A",
              height: "15px",
              position: "fixed",
              bottom: "118px",
              left: "40%",
              zIndex: 11,
              borderRadius: "50% 50% 50% 50% / 100% 100% 0% 0%",
              borderTop: "2px solid #822eda",
            }}
          ></div>
          <div
            style={{
              width: "90%",
              backgroundColor: "#272B3A",
              height: "90px",
              position: "fixed",
              bottom: "30px",
              left: "5%",
              zIndex: 10,
              borderRadius: "60px",
              border: "2px solid #822eda",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                alignItems: "center",
                height: "100%",
                paddingBottom: "8px",
              }}
            >
              <Nav.Link href={"token-admin"} className="m-2">
                Token Admin
              </Nav.Link>
              {siteContent &&
                siteContent.map((e) => (
                  <Nav.Link
                    href={e.url}
                    key={e.id}
                    target={e.new_tab && "_blank"}
                    className="m-2"
                  >
                    {e.title}
                  </Nav.Link>
                ))}
            </div>
          </div>
        </>
      )}
      <div style={{ margin: 0, padding: 0 }}>
        <Navbar collapseOnSelect expand="md" className="navbar-bg">
          <Container className="pt-1">
            <Navbar.Brand href="/">
              <img
                alt=""
                src={logo && info.apiUrl + "assets/" + logo}
                width="150"
                className="d-inline-block align-top img-fluid"
              />{" "}
            </Navbar.Brand>
            {isSmallDevice && (
              <div
                style={{
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                  border: "2px solid #822eda",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                  padding: 0,
                  cursor: "pointer",
                  backgroundColor: "black",
                }}
                onClick={() => setIsHovered((e) => !e)}
              >
                {isHovered ? (
                  <i className="fa-solid fa-xmark"></i>
                ) : (
                  <i className="fa-solid fa-bars"></i>
                )}
              </div>
            )}
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav className="me-auto">
                <Nav.Link href={"token-admin"} className="m-2">
                  Token Admin
                </Nav.Link>
                {siteContent &&
                  siteContent.map((e) => (
                    <Nav.Link
                      href={e.url}
                      key={e.id}
                      target={e.new_tab && "_blank"}
                      className="m-2"
                    >
                      {e.title}
                    </Nav.Link>
                  ))}
              </Nav>
            </Navbar.Collapse>
            <Nav>
              <Nav.Link>
                <ButtonCustom
                  title={!connection ? "Connect" : "Disconnect"}
                  onClick={connectWeb3}
                />
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        <div className="container-fluid mb-5">
          <div className="header-curve"></div>
        </div>
      </div>
    </>
  );
}

export default Header;
