import React from "react";

function Footer({ text }) {
  return (
    <>
      <div className="container-fluid" style={{ padding: 0 }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <div className="footer-curve"></div>
        </div>
        <div className="footer-bg">
          <p>{text && text}</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
