import React from "react";

function Range({ onChange }) {
  return (
    <div style={{ padding: "10px 30px" }}>
      <input
        type="range"
        className="form-range custom-range"
        defaultValue={5}
        min={0}
        max={10}
        required
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export default Range;
