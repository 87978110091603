import React from "react";
import { Spinner } from "react-bootstrap";

function Loading() {
  return (
    <div className="container-fluid">
      <div
        style={{
          width: "100%",
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    </div>
  );
}

export default Loading;
